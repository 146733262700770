import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home Screen",
    component: () => import("../views/HomeScreen.vue"),
  },
  {
    path: "/complaints",
    name: "Complaint Overview",
    component: () => import("../views/ComplaintOverview.vue"),
  },
  {
    path: "/complaints/:id",
    name: "Complaint Single",
    component: () => import("../views/ComplaintSingle.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
